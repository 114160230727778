<template>
  <div
    class="collapse navbar-collapse w-auto h-auto h-100"
    id="sidenav-collapse-main"
  >
    <ul class="navbar-nav">
      <li class="nav-item">
        <sidenav-item
          url="/dashboard"
          :class="getRoute() === 'dashboard-default' ? 'active' : ''"
          :navText="'Dashboard'"
        >
          <template v-slot:icon>
            <i class="ni ni-tv-2 text-primary text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user && $store.state.auth.user.email === 'erik@aderize.com'" class="nav-item">
        <sidenav-item
          url="/tables"
          :class="getRoute() === 'tables' ? 'active' : ''"
          :navText="'Tables'"
        >
          <template v-slot:icon>
            <i
              class="ni ni-calendar-grid-58 text-warning text-sm opacity-10"
            ></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user && $store.state.auth.user.email === 'erik@aderize.com'" class="nav-item">
        <sidenav-item
          url="/billing"
          :class="getRoute() === 'billing' ? 'active' : ''"
          :navText="'Billing'"
        >
          <template v-slot:icon>
            <i class="ni ni-credit-card text-success text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/filemanager"
          :class="getRoute() === 'filemanager' ? 'active' : ''"
          :navText="'File manager'"
        >
          <template v-slot:icon>
            <i class="ni ni-folder-17 text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user" class="nav-item">
        <sidenav-item
          url="/campaigns"
          :class="getRoute() === 'campaigns' ? 'active' : ''"
          :navText="'Campaigns'"
        >
          <template v-slot:icon>
            <i class="fa fa-list-ol text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user && $store.state.auth.user.email === 'erik@aderize.com'" class="nav-item">
        <sidenav-item
          url="/admin"
          :class="getRoute() === 'admin' ? 'active' : ''"
          :navText="'Admin'"
        >
          <template v-slot:icon>
            <i class="fa fa-lock text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user && $store.state.auth.user.email === 'erik@aderize.com'" class="nav-item">
        <sidenav-item
          url="/admin/invite"
          :class="getRoute() === 'admin/invite' ? 'active' : ''"
          :navText="'Invite users'"
        >
          <template v-slot:icon>
            <i class="fa fa-user text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li class="mt-3 nav-item nav-category">
        <h6
          class="text-xs ps-4 text-uppercase font-weight-bolder opacity-6"
          :class="'ms-2'"
        >
          ACCOUNT PAGES
        </h6>
      </li>
      <li class="nav-item">
        <sidenav-item
          url="/profile"
          :class="getRoute() === 'profile' ? 'active' : ''"
          :navText="'Profile'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-02 text-dark text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user && $store.state.auth.user.email === 'erik@aderize.com'" class="nav-item">
        <sidenav-item
          url="/signin"
          :class="getRoute() === 'signin' ? 'active' : ''"
          :navText="'Sign In'"
        >
          <template v-slot:icon>
            <i class="ni ni-single-copy-04 text-danger text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
      <li v-if="$store.state.auth.user && $store.state.auth.user.email === 'erik@aderize.com'" class="nav-item">
        <sidenav-item
          url="/signup"
          :class="getRoute() === 'signup' ? 'active' : ''"
          :navText="'Sign Up'"
        >
          <template v-slot:icon>
            <i class="ni ni-collection text-info text-sm opacity-10"></i>
          </template>
        </sidenav-item>
      </li>
    </ul>
  </div>
</template>
<script>
import SidenavItem from "./SidenavItem.vue";

export default {
  name: "SidenavList",
  props: {
    cardBg: String
  },
  data() {
    return {
      title: "Aderize Admin Dashboard",
      controls: "dashboardsExamples",
      isActive: "active",
      showAdminDrop: false
    };
  },
  components: {
    SidenavItem
  },
  methods: {
    getRoute() {
      const routeArr = this.$route.path.split("/");
      return routeArr[1];
    }
  }
};
</script>
