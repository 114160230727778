<template>
  <nav
    class="navbar navbar-main navbar-expand-lg px-0 mx-4 shadow-none border-radius-xl"
    :class="
      ''
    "
    v-bind="$attrs"
    id="navbarBlur"
    data-scroll="true"
  >
    <div class="px-3 py-1 container-fluid">
      <breadcrumbs v-if="$store.state.showHeaderInfo" :currentPage="currentRouteName" :pageRoute="currentRoute" textWhite="text-white" />

      <div
        class="mt-2 collapse navbar-collapse mt-sm-0 me-md-0 me-sm-4"
        :class="'me-sm-4'"
        id="navbar"
      >
        <div
          class="pe-md-3 d-flex align-items-center"
          :class="'ms-md-auto'"
        >
          <div class="nav-item d-flex align-items-right text-white">
            <div class="form-check form-switch ps-0 ms-auto my-auto">
              <span class="d-sm-inline d-none me-md-3">Light </span>
              <input
                class="form-check-input mt-1 ms-auto"
                type="checkbox"
                :checked="this.$store.state.darkMode"
                @click="setDarkMode"
              />
              <span class="d-sm-inline ms-md-3"> Dark</span>
            </div>
          </div>
        </div>
        <!-- <div
          class="pe-md-3 d-flex align-items-center"
        >
          <div class="input-group">
            <span class="input-group-text text-body">
              <i class="fas fa-search" aria-hidden="true"></i>
            </span>
            <input
              type="text"
              class="form-control"
              placeholder="
                Search from here
              "
            />
          </div>
        </div> -->
        <ul class="navbar-nav justify-content-end">
          <li v-if="this.$store.state.auth.user" class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Profile' }"
              class="px-0 nav-link font-weight-bold text-white"
            >
              <i
                class="fa fa-user"
                :class="'me-sm-2'"
              ></i>
              <span class="d-sm-inline d-none">{{ this.$store.state.auth.user.first_name }} {{ this.$store.state.auth.user.last_name }}</span>
            </router-link>
          </li>
          <li v-else-if="$store.state.showHeaderInfo" class="nav-item d-flex align-items-center">
            <router-link
              :to="{ name: 'Signin' }"
              class="px-0 nav-link font-weight-bold text-white"
              target="_blank"
            >
              <i
                class="fa fa-user"
                :class="'me-sm-2'"
              ></i>
              <span class="d-sm-inline d-none">Sign In</span>
            </router-link>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
import Breadcrumbs from "../Breadcrumbs.vue";
import { mapMutations, mapActions } from "vuex";
import { activateDarkMode, deactivateDarkMode } from "@/assets/js/dark-mode";

export default {
  name: "navbar",
  data() {
    return {
      showMenu: false
    };
  },
  props: ["minNav", "textWhite"],
  created() {

    this.minNav;
  },
  methods: {
    ...mapMutations(["navbarMinimize", "toggleConfigurator"]),
    ...mapActions(["toggleSidebarColor"]),

    toggleSidebar() {
      this.toggleSidebarColor("bg-white");
      this.navbarMinimize();
    },

    setDarkMode() {
      if (this.$store.state.darkMode) {
        this.$store.state.darkMode = false;
        this.$store.state.sidebarType = "bg-white";
        deactivateDarkMode();
        return;
      } else {
        this.$store.state.darkMode = true;
        this.$store.state.sidebarType = "bg-default";
        activateDarkMode();
      }
    },
  },
  components: {
    Breadcrumbs
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currentRoute() {
      return this.$route.matched.map((key) => { return key.name});
    }
  }
};
</script>
